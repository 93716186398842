.AddtionalCommentsComponent {
	font-family: Arial, sans-serif;
}

.container {
	max-width: 75%;
	margin: 0 auto;
	background: #fff;
	padding: 30px;
	border-radius: 10px;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
	/* background: linear-gradient(135deg, #ffffff, #e9ecef); */
}

.title {
	font-size: 1.8rem;
	color: #8cc642;
	/* margin-bottom: 20px; */
	font-weight: bold;
}

.sectionTitle {
	font-size: 1.2rem;
	color: #8cc642;
	margin-top: 80px;
	font-weight: 600;
}

section {
	margin-bottom: 40px;
}

.mostFrequentMistake .mistakeDescription {
	font-size: 1rem;
	line-height: 1.6;
	margin-bottom: 20px;
}

.mostFrequentMistake strong {
	/* color: #4e73df; */
}

.mistakeList {
	list-style-type: none;
	padding: 0;
}

.mistakeItem {
	background: #e1e8ff;
	margin: 10px 0;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 1rem;
	color: #333;
}

.tableWrapper {
	overflow-x: auto;
	margin-top: 30px;
}

.table {
	width: 100%;
	border-collapse: collapse;
	/* margin-top: 20px; */
}

.tableHeader {
	padding: 15px !important;
	text-align: left;
	font-size: 1rem;
	background-color: #8cc642 !important;
	color: white;
}

.tableCell {
	padding: 15px;
	text-align: left;
	font-size: 1rem;
	background-color: #f7f9fc;
}

.tableRow:nth-child(even) .tableCell {
	background-color: #e1e8ff;
}

/* .tableRow:hover .tableCell {
	background-color: #d1dbe6;
} */

.card {
	background: #fff;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 20px;
	/* margin-top: 20px; */
	/* margin-bottom: 20px; */
	transition: transform 0.3s;
}

.card:hover {
	transform: translateY(-5px);
}

/* .button {
	display: inline-block;
	background-color: #8cc642;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	font-weight: 600;
	text-decoration: none;
	transition: background-color 0.3s;
	margin-top: 30px;
}

.button:hover {
	background-color: #6d9c35;
} */

.section-title {
	font-size: 1.2rem;
	font-weight: bold;
	color: #8cc642;
	margin-top: 20px;
}

.section-content {
	margin: 10px 0;
	font-size: 1rem;
	line-height: 1.6;
	color: #555;
}

.box {
	padding: 15px;
	background-color: #f1f8ff;
	border-radius: 5px;
	border-left: 5px solid #8cc642;
}

.score-box {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	background-color: #e9f7fd;
	border-radius: 5px;
	margin-top: 20px;
}

.score-box span {
	font-weight: bold;
}

.score-box span {
	font-weight: bold;
}

.step-list {
	list-style-type: none;
	padding: 0;
}

.step-list li {
	background: #e1e8ff;
	margin: 10px 0;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 1.1rem;
	color: #333;
}

.guidance .box {
	padding: 15px;
	background-color: #f1f8ff;
	border-radius: 5px;
	border-left: 5px solid #8cc642;
}

.stepByStepPlan .box {
	padding: 15px;
	background-color: #f1f8ff;
	border-radius: 5px;
	border-left: 5px solid #8cc642;
}

.sectionTitle {
	font-size: 1.3rem;
	color: #8cc642;
	font-weight: 600;
	margin-top: 20px;
}

.sectionContent {
	margin: 10px 0;
	font-size: 1rem;
	line-height: 1.6;
	color: #555;
}

.stepList {
	list-style-type: none;
	padding: 0;
}

.stepList li {
	background: #e1e8ff;
	margin: 10px 0;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	font-size: 1.1rem;
	color: #333;
}


.matrixTable {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

.matrixHeader {
	padding: 12px;
	text-align: left;
	font-size: 1.1rem;
	background-color: #8cc642;
	color: white;
}

.matrixCell {
	padding: 12px;
	text-align: left;
	font-size: 1rem;
	border: 1px solid #ddd;
}

.matrixTable tr:nth-child(even) {
	background-color: #f9f9f9;
}

.matrixTable tr:hover {
	background-color: #f1f8ff;
}

.scoreBox {
	display: flex;
	justify-content: space-between;
	padding: 15px;
	background-color: #e9f7fd;
	border-radius: 5px;

}